var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.modalData.data)?_c('div',{staticClass:"modal fade",attrs:{"id":"modal-form-cam"}},[_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[(_vm.modalData.data)?_c('h4',{staticClass:"modal-title text-white"},[_vm._v(" Autorizar - "+_vm._s(_vm.modalData.data.ntipo_autorizacion)+" ")]):_vm._e(),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',[_vm._v("Estado")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.estado),expression:"form.estado"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.estado.$invalid ? 'is-invalid' : 'is-valid',attrs:{"disabled":_vm.modalData.data.estado != 1},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "estado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.estados),function(estado){return _c('option',{key:estado.numeracion,domProps:{"value":estado.numeracion}},[_vm._v(" "+_vm._s(estado.descripcion)+" ")])})],2)]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Observaciones")]),_c('br'),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.observacion_autoriza),expression:"form.observacion_autoriza"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.observacion_autoriza.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"rows":"2","disabled":_vm.modalData.data.estado != 1},domProps:{"value":(_vm.form.observacion_autoriza)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "observacion_autoriza", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"imagen"}},[_vm._v("Adjunto")]),_c('br'),(!_vm.modalData.data.link_documento)?_c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control-file",attrs:{"type":"file","id":"archivo-adjunto","accept":"image/x-png,image/gif,image/jpeg,application/pdf,"},on:{"change":_vm.obtenerArchivo}})]):_c('div',{staticClass:"btn-group text-center"},[_c('a',{staticClass:"btn btn-primary btn-sm",attrs:{"target":"_blank","href":_vm.uri_docs + _vm.data_autorizacion.link_documento}},[_c('i',{staticClass:"fa fa-download"})])]),(_vm.fileAdjunto)?_c('div',{staticClass:"form-group float-right"},[_c('button',{staticClass:"btn btn-secondary col-md-12",attrs:{"type":"button"},on:{"click":_vm.limpiarArchivo}},[_c('i',{staticClass:"fas fa-eraser"})])]):_vm._e()])]),(_vm.data_autorizacion && _vm.modalData.data)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card card-info card-outline"},[_c('div',{staticClass:"card-header pt-2 pb-2"},[(_vm.modalData.data)?_c('h3',{staticClass:"card-title"},[_c('i',{staticClass:"far fa-circle"}),_vm._v(" Detalles - "+_vm._s(_vm.modalData.data.ntipo_autorizacion)+" ")]):_vm._e(),_vm._m(0)]),(_vm.modalData.data)?_c('div',{staticClass:"card-body",staticStyle:{"display":"block"}},[_c('div',{staticClass:"row"},[(
                        _vm.data_autorizacion.tipo_autorizacion == 1 ||
                          _vm.data_autorizacion.tipo_autorizacion == 8
                      )?_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v("N° Inspección")]),_c('p',[_vm._v(" "+_vm._s(_vm.data_autorizacion.det_autorizaciones[0].model_id)+" ")])]),_c('div',{staticClass:"col-md-6 text-right"},[_c('p',[_c('button',{staticClass:"btn btn-sm bg-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.verPDF(
                                  _vm.data_autorizacion.det_autorizaciones[0]
                                    .model_id
                                )}}},[_c('i',{staticClass:"fas fa-file-pdf"})])])])])]):_vm._e(),_c('div',{staticClass:"form-group col-md-4"},[_c('div',{staticClass:"row"},[(_vm.data_autorizacion.user_solicita)?_c('div',{staticClass:"col-md-12"},[_c('strong',[_vm._v("Usuario Solicita")]),_c('p',[_vm._v(_vm._s(_vm.data_autorizacion.user_solicita.name))])]):_vm._e(),(_vm.data_autorizacion.user_autoriza)?_c('div',{staticClass:"col-md-12"},[_c('strong',[_vm._v("Usuario Autoriza")]),_c('p',[_vm._v(_vm._s(_vm.data_autorizacion.user_autoriza.name))])]):_vm._e()])]),_c('div',{staticClass:"form-group col-md-8"},[_c('strong',[_vm._v("Justificación")]),_c('p',[_vm._v(_vm._s(_vm.data_autorizacion.justificacion))])]),(
                        _vm.data_autorizacion.tipo_autorizacion == 1 &&
                          _vm.info_adicional
                      )?_c('div',{staticClass:"col-md-12"},[_c('div',{attrs:{"id":"accordion-cierres-novedades"}},[_c('div',{staticClass:"card bg-frontera"},[_vm._m(1),_c('div',{staticClass:"collapse",attrs:{"id":"collapse_cierre-novedades","data-parent":"#accordion-cierres-novedades"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"card-body table-responsive bg-white"},[(_vm.info_adicional.cierres_novedades)?_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm",staticStyle:{"font-size":"0.85em"}},[_vm._m(2),_c('tbody',_vm._l((_vm.info_adicional
                                        .cierres_novedades
                                        .detalles_inspecciones),function(det,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge",class:det.cierre_novedad_inspeccion
                                              ? 'bg-success'
                                              : 'bg-secondary'},[_vm._v(" "+_vm._s(det.cierre_novedad_inspeccion ? "Cerrado" : "Pendiente")+" ")])]),_c('td',{staticClass:"text-center text-nowrap"},[_vm._v(" "+_vm._s(_vm.info_adicional.cierres_novedades .fecha_inicio)+" - "+_vm._s(_vm.info_adicional.cierres_novedades .fecha_fin)+" ")]),_c('td',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(det.secciones_has_items_inspeccion .seccion.nombre)+" ")]),_c('td',[_vm._v(" "+_vm._s(det.secciones_has_items_inspeccion .item.nombre)+" ")]),_c('td',[_vm._v(_vm._s(det.valor))]),_c('td',{staticClass:"text-center"},[(
                                            !det.cierre_novedad_inspeccion
                                          )?_c('div',[_c('span',{staticClass:"badge badge-secondary"},[_vm._v("Sin Cierre")])]):_vm._e(),(
                                            det.cierre_novedad_inspeccion &&
                                              det.cierre_novedad_inspeccion
                                                .url
                                          )?_c('div',[_c('a',{staticClass:"btn btn-primary btn-sm",attrs:{"target":"_blank","href":_vm.uri_docs +
                                                det.cierre_novedad_inspeccion
                                                  .url}},[_c('i',{staticClass:"fa fa-download"})])]):_vm._e()])])}),0)]):_vm._e()])])])])])]):_vm._e(),_c('div',{staticClass:"col-md-12"},[_c('table',{staticClass:"table table-sm table-hover"},[_vm._m(3),_c('tbody',_vm._l((_vm.data_autorizacion.datos),function(dato,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(dato[0]))]),_c('td',[_vm._v(_vm._s(dato[1]))]),(_vm.data_autorizacion.tipo_autorizacion != 23)?_c('td',[_vm._v(" "+_vm._s(dato[2])+" ")]):_c('td',[(
                                  typeof dato[2] === 'string' &&
                                    dato[2].startsWith(
                                      'https://www.supportsos7-24.com/'
                                    )
                                )?_c('span',[_c('a',{staticClass:"btn btn-sm bg-danger",attrs:{"href":dato[2],"target":"_blank"}},[_c('i',{staticClass:"fas fa-file-pdf"}),_vm._v(" Ver PDF ")])]):_c('span',[_vm._v(" "+_vm._s(dato[2])+" ")])])])}),0)])])])]):_vm._e()])])]):_vm._e()]),(
            _vm.$store.getters.can('admin.autorizaciones.edit') &&
              !_vm.$v.form.$invalid &&
              _vm.modalData.data.estado == 1
          )?_c('div',{staticClass:"modal-footer justify-content-between"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guardar ")])]):_vm._e()])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","data-card-widget":"collapse","data-toggle":"tooltip","title":"Collapse"}},[_c('i',{staticClass:"fas fa-minus"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header p-2"},[_c('a',{staticClass:"d-block w-100 collapsed",attrs:{"data-toggle":"collapse","href":"#collapse_cierre-novedades","aria-expanded":"false"}},[_c('h4',{staticClass:"card-title w-100 text-white"},[_vm._v(" Cierres Novedades Inspección ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-dark"},[_c('tr',{staticClass:"text-nowrap"},[_c('th',[_vm._v("Estado")]),_c('th',[_vm._v("Fecha")]),_c('th',[_vm._v("Sección")]),_c('th',[_vm._v("Item")]),_c('th',[_vm._v("Observación - Valor")]),_c('th')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',[_vm._v("Campo")]),_c('th',[_vm._v("Valor Anterior")]),_c('th',[_vm._v("Valor Nuevo")])])])
}]

export { render, staticRenderFns }