<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Autorizaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Autorizaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-4">
                    <label> Buscar en la tabla</label>
                    <input
                      type="text"
                      class="form-control form-control-sm border border-dark shadow"
                      v-model="filtros.filter"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <div class="col-md-3 text-center">
                    <div
                      v-if="cargando"
                      class="spinner-border text-dark"
                      role="status"
                    ></div>
                  </div>
                  <div class="col-md-5">
                    <div class="float-right">
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync fa-spin"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0 table-responsive">
                <table
                  id="autorizaciones"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                  v-if="$store.getters.can('admin.autorizaciones.index')"
                >
                  <thead class="thead-dark">
                    <tr>
                      <!---Inicio Select Filtro Tipo Solicitud --->
                      <th style="min-width: 15em">
                        Tipo Solicitud
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_solicitud"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <template
                            v-for="tipo_solicitud in listasForms.tiposolicitud"
                          >
                            <option
                              v-if="
                                $store.getters.can(
                                  'admin.autorizaciones.tipo_Solicitud_' +
                                    tipo_solicitud.descripcion
                                )
                              "
                              :key="tipo_solicitud.numeracion"
                              :value="tipo_solicitud.numeracion"
                            >
                              {{ tipo_solicitud.descripcion }}
                            </option>
                          </template>
                        </select>
                      </th>
                      <th class="text-center p-3">Cambios</th>
                      <!---Inicio Input Filtro Usuario Solicita --->
                      <th style="min-width: 15em">
                        Usuario Solicita
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          class="form-control form-control-sm p-0"
                          v-model="usuario_solicita_id"
                          placeholder="Usuario"
                          label="name"
                          :options="listasForms.usuario"
                          @search="buscarUsuario"
                          @input="getIndex()"
                        >
                        </v-select>
                      </th>
                      <!---Inicio Input Filtro Usuario Autoriza --->
                      <th style="min-width: 15em">
                        Usuario Autoriza
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          class="form-control form-control-sm p-0"
                          v-model="usuario_autoriza_id"
                          placeholder="Usuario"
                          label="name"
                          :options="listasForms.usuario"
                          @search="buscarUsuario"
                          @input="getIndex()"
                        >
                        </v-select>
                      </th>
                      <!---Inicio Input Filtro Estado --->
                      <th style="min-width: 10em">
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center p-3">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="autorizacion in autorizaciones.data"
                      :key="autorizacion.id"
                    >
                      <td class="text-left">
                        {{ autorizacion.ntipo_autorizacion }}
                      </td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <table
                              id="autorizaciones"
                              class="table table-sm table-bordered none"
                            >
                              <tbody
                                v-for="campo in autorizacion.datos"
                                :key="campo.id"
                              >
                                <td class="none">
                                  <span class="badge bg-pill text-left">
                                    {{ campo[0] }}
                                  </span>
                                </td>
                                <td class="none">
                                  <span
                                    class="badge alert-default-warning text-left"
                                    v-if="campo[1]"
                                  >
                                    {{ campo[1] }}
                                  </span>
                                  <span
                                    class="badge alert-default-danger text-left"
                                    v-else
                                  >
                                    Sin Dato
                                  </span>
                                </td>
                                <td class="none">
                                  <span
                                    class="badge alert-default-success text-left"
                                    v-if="campo[2]"
                                    >{{ campo[2] }}</span
                                  >
                                  <span
                                    class="badge alert-default-danger text-left"
                                    v-else
                                  >
                                    Sin Dato
                                  </span>
                                </td>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </td>
                      <td class="text-center">
                        <span v-if="autorizacion.user_solicita">{{
                          autorizacion.user_solicita.name
                        }}</span>
                      </td>
                      <td class="text-center">
                        <span v-if="autorizacion.user_autoriza">{{
                          autorizacion.user_autoriza.name
                        }}</span>
                      </td>
                      <td class="text-center">
                        <button
                          v-if="autorizacion.estado == 1"
                          class="badge badge-warning"
                          style="cursor: pointer"
                        >
                          PENDIENTE
                          <i class="fas fa-low-vision"></i>
                        </button>

                        <button
                          v-if="autorizacion.estado == 2"
                          class="badge badge-success"
                          style="cursor: pointer"
                        >
                          AUTORIZADO
                          <i class="fas fa-check-circle"></i>
                        </button>

                        <button
                          v-if="autorizacion.estado == 3"
                          class="badge badge-danger"
                          style="cursor: pointer"
                        >
                          RECHAZADO
                          <i class="fas fa-ban"></i>
                        </button>
                      </td>
                      <td
                        class="text-center"
                        v-if="
                          autorizacion.tipo_autorizacion == 16 &&
                            autorizacion.created_at < fechaCor &&
                            autorizacion.estado == 1
                        "
                      >
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-info"
                            style="cursor: pointer"
                            @click="alertaModal()"
                          >
                            <i class="fas fa-list-alt"></i>
                          </button>
                        </div>
                      </td>
                      <td v-else>
                        <button
                          type="button"
                          class="btn btn-info"
                          data-toggle="modal"
                          data-target="#modal-form-cam"
                          style="cursor: pointer"
                          @click="getModalData(autorizacion)"
                        >
                          <i class="fas fa-list-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="autorizaciones.total">
                  Mostrando del <b>{{ autorizaciones.from }}</b> al
                  <b>{{ autorizaciones.to }}</b> de un total:
                  <b>{{ autorizaciones.total }}</b> Registros
                </div>
                <div class="float-left" v-else>
                  <span class="badge badge-danger">
                    No hay registros para mostrar
                  </span>
                </div>
                <pagination
                  :data="autorizaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <AutorizacionesCambio ref="AutorizacionesCambio" />
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import moment from "moment";
import pagination from "laravel-vue-pagination";
import AutorizacionesCambio from "./AutorizacionesCambio";
import Loading from "../../../../components/Loading";

export default {
  name: "AutorizacionesIndex",
  components: {
    pagination,
    vSelect,
    Loading,
    AutorizacionesCambio,
  },
  data() {
    return {
      autorizaciones: {},
      cargando: false,
      fechaCor: null,
      fechaAct: null,
      punto_gestion_id: null,
      usuario_solicita_id: null,
      usuario_autoriza_id: null,
      muestraModal: false,
      filtros: {
        filter: "",
        viaje_id: null,
        tipo_solicitud: null,
        punto_gestion_id: null,
        valor_anterior_id: null,
        valor_nuevo_id: null,
        usuario_solicita_id: null,
        usuario_autoriza_id: null,
        estado: null,
      },
      tarifas: {},
      listasForms: {
        tiposolicitud: [],
        usuario: [],
        estados: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      this.filtros.usuario_solicita_id = null;
      if (this.usuario_solicita_id) {
        this.filtros.usuario_solicita_id = this.usuario_solicita_id.id;
      }

      this.filtros.usuario_autoriza_id = null;
      if (this.usuario_autoriza_id) {
        this.filtros.usuario_autoriza_id = this.usuario_autoriza_id.id;
      }

      axios
        .get("/api/admin/autorizaciones?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.getAutorizaciones(response.data);
          this.cargando = false;
        });
    },

    getAutorizaciones(array) {
      array.data.forEach((autorizacion, index_autorizaciuon) => {
        // Se buscan los valores a mostrar en la pantalla
        let oldValues = JSON.parse(autorizacion.datos_mostrar_ant);
        let newValues = JSON.parse(autorizacion.datos_mostrar_nuevos);
        let arrayChanges = new Array();
        let arrayKeys = new Array();

        if (oldValues || newValues) {
          if (oldValues.length == 0) {
            arrayKeys = Object.keys(newValues);
          } else {
            arrayKeys = Object.keys(oldValues);
          }
          let arrayOldValues = Object.entries(oldValues);
          let arrayNewValues = Object.entries(newValues);
          for (let j = 0; j < arrayKeys.length; j++) {
            let nValue = arrayNewValues.length > 0 ? arrayNewValues[j][1] : "";
            let oValue = arrayOldValues.length > 0 ? arrayOldValues[j][1] : "";
            arrayChanges.push([arrayKeys[j], oValue, nValue]);
          }
        }
        array.data[index_autorizaciuon].datos = arrayChanges;
      });
      this.autorizaciones = array;
    },

    //Función buscar los tipos de solicitud de listas items
    getTipo_solicitud() {
      axios.get("/api/lista/41").then((response) => {
        this.listasForms.tiposolicitud = response.data;
      });
    },

    //Función buscar los estados de autorizaciones de listas items
    getEstados() {
      axios.get("/api/lista/49").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    //Función buscar los usuarios
    buscarUsuario(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/usuarios/lista?name=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.usuario = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getModalData(data) {
      this.$refs.AutorizacionesCambio.modalData = {
        data,
      };
      this.$refs.AutorizacionesCambio.getIndex();
    },

    fechaCorte() {
      this.fechaAct = moment().format("YYYY-MM-DD HH:mm:ss");
      this.fechaCor = moment(this.fechaAct)
        .add(-48, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
    },

    alertaModal() {
      this.$swal({
        title: "No es posible autorizar!!",
        text: "Se excedió la fecha limite",
        icon: "error",
        confirmButtonText: "Aceptar!",
      });
    },
  },
  mounted() {
    this.getIndex();
    this.getEstados();
    this.fechaCorte();
    this.getTipo_solicitud();
  },
};
</script>
<style>
.none {
  border: none !important;
}
</style>
