<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-cam" v-if="modalData.data">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white" v-if="modalData.data">
              Autorizar - {{ modalData.data.ntipo_autorizacion }}
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- Formulario -->
              <div class="form-group col-md-3">
                <label>Estado</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.estado"
                  :class="$v.form.estado.$invalid ? 'is-invalid' : 'is-valid'"
                  :disabled="modalData.data.estado != 1"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in listasForms.estados"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>Observaciones</label><br />
                <textarea
                  rows="2"
                  class="form-control form-control-sm"
                  v-model="form.observacion_autoriza"
                  :class="
                    $v.form.observacion_autoriza.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  :disabled="modalData.data.estado != 1"
                >
                </textarea>
              </div>
              <div class="form-group col-md-3">
                <label for="imagen">Adjunto</label><br />
                <div class="form-group" v-if="!modalData.data.link_documento">
                  <input
                    type="file"
                    id="archivo-adjunto"
                    class="form-control-file"
                    accept="image/x-png,image/gif,image/jpeg,application/pdf,"
                    @change="obtenerArchivo"
                  />
                </div>
                <div v-else class="btn-group text-center">
                  <a
                    target="_blank"
                    class="btn btn-primary btn-sm"
                    :href="uri_docs + data_autorizacion.link_documento"
                  >
                    <i class="fa fa-download"></i>
                  </a>
                </div>
                <div class="form-group float-right" v-if="fileAdjunto">
                  <button
                    type="button"
                    class="btn btn-secondary col-md-12"
                    @click="limpiarArchivo"
                  >
                    <i class="fas fa-eraser"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row" v-if="data_autorizacion && modalData.data">
              <div class="col-md-12">
                <div class="card card-info card-outline">
                  <div class="card-header pt-2 pb-2">
                    <h3 class="card-title" v-if="modalData.data">
                      <i class="far fa-circle"></i>
                      Detalles - {{ modalData.data.ntipo_autorizacion }}
                    </h3>
                    <div class="card-tools">
                      <button
                        type="button"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                        class="btn btn-tool"
                      >
                        <i class="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="card-body"
                    style="display: block"
                    v-if="modalData.data"
                  >
                    <div class="row">
                      <div
                        class="col-md-12"
                        v-if="
                          data_autorizacion.tipo_autorizacion == 1 ||
                            data_autorizacion.tipo_autorizacion == 8
                        "
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <strong>N° Inspección</strong>
                            <p>
                              {{
                                data_autorizacion.det_autorizaciones[0].model_id
                              }}
                            </p>
                          </div>
                          <div class="col-md-6 text-right">
                            <p>
                              <button
                                type="button"
                                class="btn btn-sm bg-danger"
                                @click="
                                  verPDF(
                                    data_autorizacion.det_autorizaciones[0]
                                      .model_id
                                  )
                                "
                              >
                                <i class="fas fa-file-pdf"></i>
                              </button>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <div class="row">
                          <div
                            class="col-md-12"
                            v-if="data_autorizacion.user_solicita"
                          >
                            <strong>Usuario Solicita</strong>
                            <p>{{ data_autorizacion.user_solicita.name }}</p>
                          </div>
                          <div
                            class="col-md-12"
                            v-if="data_autorizacion.user_autoriza"
                          >
                            <strong>Usuario Autoriza</strong>
                            <p>{{ data_autorizacion.user_autoriza.name }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-8">
                        <strong>Justificación</strong>
                        <p>{{ data_autorizacion.justificacion }}</p>
                      </div>
                      <!-- Información Adicional - Inspección Rechazadas (1) -->
                      <div
                        class="col-md-12"
                        v-if="
                          data_autorizacion.tipo_autorizacion == 1 &&
                            info_adicional
                        "
                      >
                        <div id="accordion-cierres-novedades">
                          <div class="card bg-frontera">
                            <div class="card-header p-2">
                              <a
                                data-toggle="collapse"
                                href="#collapse_cierre-novedades"
                                aria-expanded="false"
                                class="d-block w-100 collapsed"
                                ><h4 class="card-title w-100 text-white">
                                  Cierres Novedades Inspección
                                </h4></a
                              >
                            </div>
                            <div
                              id="collapse_cierre-novedades"
                              data-parent="#accordion-cierres-novedades"
                              class="collapse"
                            >
                              <div class="row">
                                <div
                                  class="card-body table-responsive bg-white"
                                >
                                  <table
                                    v-if="info_adicional.cierres_novedades"
                                    class="table table-bordered table-striped table-hover table-sm"
                                    style="font-size: 0.85em"
                                  >
                                    <thead class="bg-dark">
                                      <tr class="text-nowrap">
                                        <th>Estado</th>
                                        <th>Fecha</th>
                                        <th>Sección</th>
                                        <th>Item</th>
                                        <th>Observación - Valor</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <!-- v-for="(det, index) in novedades.detalles_inspecciones" -->
                                      <tr
                                        v-for="(det, index) in info_adicional
                                          .cierres_novedades
                                          .detalles_inspecciones"
                                        :key="index"
                                      >
                                        <td class="text-center">
                                          <span
                                            class="badge"
                                            :class="
                                              det.cierre_novedad_inspeccion
                                                ? 'bg-success'
                                                : 'bg-secondary'
                                            "
                                          >
                                            {{
                                              det.cierre_novedad_inspeccion
                                                ? "Cerrado"
                                                : "Pendiente"
                                            }}
                                          </span>
                                        </td>
                                        <td class="text-center text-nowrap">
                                          {{
                                            info_adicional.cierres_novedades
                                              .fecha_inicio
                                          }}
                                          -
                                          {{
                                            info_adicional.cierres_novedades
                                              .fecha_fin
                                          }}
                                        </td>
                                        <td class="text-nowrap">
                                          {{
                                            det.secciones_has_items_inspeccion
                                              .seccion.nombre
                                          }}
                                        </td>
                                        <td>
                                          {{
                                            det.secciones_has_items_inspeccion
                                              .item.nombre
                                          }}
                                        </td>
                                        <td>{{ det.valor }}</td>
                                        <td class="text-center">
                                          <div
                                            v-if="
                                              !det.cierre_novedad_inspeccion
                                            "
                                          >
                                            <span class="badge badge-secondary"
                                              >Sin Cierre</span
                                            >
                                          </div>
                                          <div
                                            v-if="
                                              det.cierre_novedad_inspeccion &&
                                                det.cierre_novedad_inspeccion
                                                  .url
                                            "
                                          >
                                            <a
                                              target="_blank"
                                              class="btn btn-primary btn-sm"
                                              :href="
                                                uri_docs +
                                                  det.cierre_novedad_inspeccion
                                                    .url
                                              "
                                            >
                                              <i class="fa fa-download"></i>
                                            </a>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <table class="table table-sm table-hover">
                          <thead class="thead-dark">
                            <tr>
                              <th>Campo</th>
                              <th>Valor Anterior</th>
                              <th>Valor Nuevo</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(dato, index) in data_autorizacion.datos"
                              :key="index"
                            >
                              <td>{{ dato[0] }}</td>
                              <td>{{ dato[1] }}</td>
                              <td
                                v-if="data_autorizacion.tipo_autorizacion != 23"
                              >
                                {{ dato[2] }}
                              </td>
                              <td v-else>
                                <span
                                  v-if="
                                    typeof dato[2] === 'string' &&
                                      dato[2].startsWith(
                                        'https://www.supportsos7-24.com/'
                                      )
                                  "
                                >
                                  <a
                                    :href="dato[2]"
                                    target="_blank"
                                    class="btn btn-sm bg-danger"
                                  >
                                    <i class="fas fa-file-pdf"></i>
                                    Ver PDF
                                  </a>
                                </span>
                                <span v-else>
                                  {{ dato[2] }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="
              $store.getters.can('admin.autorizaciones.edit') &&
                !$v.form.$invalid &&
                modalData.data.estado == 1
            "
          >
            <button type="button" class="btn btn-primary" @click="save()">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AutarizacionesCambio",
  components: {},
  data() {
    return {
      modalData: {},
      data_autorizacion: {},
      info_adicional: null,
      fileAdjunto: null,
      archivoValido: true,
      link_documento: null,
      form: {
        id: null,
        estado: null,
        observacion_autoriza: null,
        link_documento: null,
      },
      sitio: [],
      listasForms: {
        estados: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    form: {
      estado: { required },
      observacion_autoriza: { required },
    },
  },
  methods: {
    async getIndex() {
      this.$parent.cargando = true;
      this.data_autorizacion = {};
      this.info_adicional = null;
      await axios
        .get("api/admin/autorizaciones/show/" + this.modalData.data.id)
        .then((response) => {
          this.getAutorizaciones(response);
          this.datosAdicionalesAutorizacion(response.data);
          this.$parent.cargando = false;
        });
    },

    async getAutorizaciones(array) {
      this.data_autorizacion = {};
      await array.data.forEach((autorizacion, index_autorizacion) => {
        // Se buscan los valores a mostrar en la pantalla
        let oldValues = JSON.parse(autorizacion.datos_mostrar_ant);
        let newValues = JSON.parse(autorizacion.datos_mostrar_nuevos);
        let arrayChanges = new Array();
        let arrayKeys = new Array();

        if (oldValues || newValues) {
          if (oldValues.length == 0) {
            arrayKeys = Object.keys(newValues);
          } else {
            arrayKeys = Object.keys(oldValues);
          }
          let arrayOldValues = Object.entries(oldValues);
          let arrayNewValues = Object.entries(newValues);
          for (let j = 0; j < arrayKeys.length; j++) {
            let nValue = arrayNewValues.length > 0 ? arrayNewValues[j][1] : "";
            let oValue = arrayOldValues.length > 0 ? arrayOldValues[j][1] : "";
            arrayChanges.push([arrayKeys[j], oValue, nValue]);
          }
        }
        array.data[index_autorizacion].datos = arrayChanges;
      });
      this.data_autorizacion = array.data[0];
      this.form.id = this.data_autorizacion.id;
      this.form.estado = this.data_autorizacion.estado;
      this.form.observacion_autoriza = this.data_autorizacion.observacion_autoriza;
    },

    // Funciones para datos adicioanles de una autorizacion
    datosAdicionalesAutorizacion(autorizacion) {
      /***
       * - Se filtra el tipo de autorización
       * Se lista los métodos para sacar la información adicional
       *  dependiendo el tipo de autorizacion que se necesite
       */
      const data = autorizacion[0];

      switch (data.tipo_autorizacion) {
        case 1:
          this.infoInspeccionRechazada(data);
          break;

        default:
          this.info_adicional = null;
          break;
      }
    },

    async infoInspeccionRechazada(autorizacion) {
      this.info_adicional = null;
      const inspeccion = autorizacion.det_autorizaciones.find(
        (det) => det.model_type === "App\\Inspeccion"
      );

      if (inspeccion) {
        await axios
          .get("api/hidrocarburos/cierreNovedades/" + inspeccion.model_id)
          .then((response) => {
            if (Object.entries(response.data).length > 0) {
              this.info_adicional = {
                cierres_novedades: response.data,
              };
            }
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    // Fin - Funciones para datos adicioanles de una autorizacion

    //Función que busca los tipos de solicitud de listas item
    getEstados() {
      axios.get("/api/lista/49").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.$parent.cargando = true;
        this.getForm();
        axios({
          method: "POST",
          url: "/api/admin/autorizaciones",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: this.form,
        }).then(() => {
          this.$refs.closeModal.click();
          this.$parent.getIndex();
          this.form = {
            id: null,
            estado: null,
            observacion_autoriza: null,
            link_documento: null,
          };
          this.archivoValido = false;
          this.fileAdjunto = null;
          document.getElementById("archivo-adjunto").value = "";
          this.$parent.cargando = false;
          this.$swal({
            icon: "success",
            title: "La autorización se modificó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      }
    },

    getForm() {
      let formData = new FormData();
      formData.append("id", this.modalData.data.id);
      if (this.data_autorizacion.tipo_autorizacion == 1) {
        formData.append(
          "inspeccion_id",
          this.data_autorizacion.det_autorizaciones[0].model_id
        );
      }
      formData.append("estado", this.form.estado);
      formData.append("usuario_autoriza_id", this.$store.getters.getUser.id);
      formData.append("observacion_autoriza", this.form.observacion_autoriza);
      formData.append(
        "tipo_autorizacion",
        this.modalData.data.tipo_autorizacion
      );

      // Se valida si el formulario trae adjunto
      if (this.fileAdjunto) {
        formData.append("link_documento", this.fileAdjunto);
      }

      // Se asignan los valores de las tablas a modificar
      let data_model = [];
      this.data_autorizacion.det_autorizaciones.forEach((value, index) => {
        data_model.push({
          id: value.model_id,
          accion: value.accion,
          model_type: value.model_type,
          datos_nuevos: value.datos_nuevos,
          datos_anteriores: value.datos_anteriores,
        });
      });
      formData.append("data_model", JSON.stringify(data_model));

      this.form = formData;
    },

    verPDF(inspeccion_id) {
      this.$parent.cargando = true;
      axios({
        method: "get",
        url: "/api/admin/inspecciones/pdf/" + inspeccion_id,
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    limpiarArchivo() {
      this.fileAdjunto = null;
      document.getElementById("archivo-adjunto").value = "";
    },

    obtenerArchivo(e) {
      this.fileAdjunto = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileAdjunto = file;
        }
      } else {
        this.archivoValido = true;
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 1000000) {
        flag = false;
        this.fileAdjunto = null;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },
  },
  mounted() {
    this.getEstados();
  },
};
</script>
